import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CompaniesModel, CompanyModel } from '../../models/companies';
import { BrowseCompaniesContainer, BrowseCompaniesInnerWrapper, CompaniesListContainer, LoadingSpinnerContainer, NoResults, SearchContainer, ResultsContainer, CompanyCount, SearchContainerInner, GetKarmaCardSection } from './styles';
import { useErrorMessages } from '../../contexts/error-messages-store';
import { useAnalytics } from '../../contexts/analytics-store';
import { Waypoint } from 'react-waypoint';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';
import { TextField, TextFieldKind } from '../../components/TextField';
import { Button, ButtonLink } from '../../components/Button';
import { XIcon } from '../../components/svgs/icons/XIcon';
import { ButtonKind } from '../../components/Button/styles';
import { IThemeProps } from '../../styles/themes';
import { withTheme } from 'styled-components';
import useQuery from '../../hooks/useQuery';
import { FilterSortBar } from '../../components/FilterSortBar';
import { FilterCompaniesModal } from '../../components/FilterCompaniesModal';
import { useBrowseQuery } from '../../contexts/browse-query';
import { Ratings } from '../../models/impact';
import { companyNameSortOption, highScoreSortOption, CompanySortType, companyOptions, lowScoreSortOption } from '../../models/browse-query';
import { useSearchParams } from 'react-router-dom';
import { CompanyBadge, ICompareClickProps } from '../../components/CompanyBadge';
import { ArrowButton, ArrowButtonKind } from '../../components/ArrowButton';
import { CompareCompaniesModel } from '../../models/compare-companies';
import { CompareCompaniesModal } from '../../components/CompareCompaniesModal';
import { KarmaCollectiveBrowseSection } from '../../components/KarmaCollectiveBrowseSection';
import { useUserSession } from '../../contexts/user';
import { PillTag } from '../../components/PillTag';
import { PillTagKind, PillTagSize } from '../../components/PillTag/styles';
import KarmaCardImage from '../../../public/assets/images/empty-kw-card.png';

interface IProps extends IThemeProps {
  className?: string;
}

enum ParamType {
  Cashback = 'cashback',
  Sectors = 'sectors',
  Values = 'values',
  Unsdgs = 'unsdgs',
  Ratings = 'ratings',
  Sort = 'sort',
}

const BrowseCompaniesBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const analytics = useAnalytics();
  const requestQuery = useBrowseQuery();
  const errorMessages = useErrorMessages();
  const query = useQuery();
  const user = useUserSession();
  const sector = query.get('sector');
  const cashback = query.get('cashback');
  const companiesModel = useRef(new CompaniesModel({ limit: 20 })).current;
  const compareCompaniesModel = useRef(new CompareCompaniesModel).current;
  const [activeSort, setActiveSort] = useState(CompanySortType.CompanyName);
  const [searchTimeout, setSearchTimeout] = useState<number>(null);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showBackToTop, setShowBackToTop] = useState(false);
  const hasLoadedCompanies = useRef(false);
  const selectedFiltersCount = useMemo(() => requestQuery.selectedFiltersCount, [requestQuery.selectedFiltersCount]);

  const loadCompanies = useCallback((refresh?: boolean) => async () => {
    try {
      await companiesModel.companies[refresh ? 'refresh' : 'loadMore'](requestQuery.paramsObject);
    } catch (err: any) {
      analytics.fireEvent('BC_ErrorLoadingCompanies');
      errorMessages.push({
        title: 'Error Loading Companies',
        message: err.message,
      });
    }
  }, [requestQuery.paramsObject]);

  useEffect(() => {
    const toggleScrollUpButton = () => {
      if (window.scrollY > 500) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };

    window.addEventListener('scroll', toggleScrollUpButton);

    return () => {
      window.removeEventListener('scroll', toggleScrollUpButton);
    };
  }, []);
  
  useEffect(() => {
    const handlePop = () => {
      const newSearchParams = new URLSearchParams(window.location.search);
      
      const updateQueryParam = (paramName: ParamType, updateFunction: any) => {
        const clearParam = () => {
          if (paramName === ParamType.Values) requestQuery.clearValues();
          if (paramName === ParamType.Sectors) requestQuery.clearSectors();
          if (paramName === ParamType.Unsdgs) requestQuery.clearUnsdgs();
        };

        if (newSearchParams.has(paramName)) {
          clearParam();
          const paramValue = newSearchParams.get(paramName);
          const paramArray = paramValue.split(',');
          paramArray.map((param) => updateFunction(param));
        } else {
          clearParam();
        }
      };
    
      updateQueryParam(ParamType.Sectors, requestQuery.updateSectors);
      updateQueryParam(ParamType.Unsdgs, requestQuery.updateUnsdgs);
      updateQueryParam(ParamType.Values, requestQuery.updateValues);
    
      if (newSearchParams.has(ParamType.Ratings)) {
        requestQuery.clearRatings();
        const ratings = newSearchParams.get(ParamType.Ratings).split(',');
        
        ratings.map(rating => {
          const ratingEnum = rating === 'neutral' ? Ratings.Neutral : rating === 'positive' ? Ratings.Positive : Ratings.Negative ;
          requestQuery.updateRatings(ratingEnum);
        });
      } else {
        requestQuery.clearRatings();
      }
    
      if (newSearchParams.has(ParamType.Cashback)) {
        requestQuery.setCashbackOnly(true);
      } else {
        requestQuery.setCashbackOnly(false);
      }
    
      const sort = newSearchParams.get(ParamType.Sort);
      switch (sort) {
        case CompanySortType.HighScore:
          requestQuery.setSort(CompanySortType.HighScore);
          break;
        case CompanySortType.LowScore:
          requestQuery.setSort(CompanySortType.LowScore);
          break;
        default:
          requestQuery.setSort(CompanySortType.CompanyName);
          break;
      }

      loadCompanies(true)();
    };
    
    window.addEventListener('popstate', handlePop);
    
    return () => {
      window.removeEventListener('popstate', handlePop);
    };
  }, []);

  const buildSelectedSort = useCallback(() => {
    if (requestQuery.sort === 'companyName') return companyNameSortOption;
    if (requestQuery.sort === '-combinedScore') return highScoreSortOption;
    return lowScoreSortOption;
  }, [requestQuery.sort]);

  useEffect(() => {
    const updateQueryParamArray = (paramName: string, updateFunction: any) => {
      const paramValue = searchParams.get(paramName);
      if (!paramValue) return;
      const values = paramValue.split(',');
      values.map(value => updateFunction(value));
    };
    updateQueryParamArray('values', requestQuery.updateValues);
    updateQueryParamArray('sectors', requestQuery.updateSectors);
    updateQueryParamArray('unsdgs', requestQuery.updateUnsdgs);

    if (searchParams.get('ratings')) {
      const ratings = searchParams.get('ratings').split(',');
      ratings.map(rating => {
        const ratingEnum = rating === 'neutral' ? Ratings.Neutral : rating === 'positive' ? Ratings.Positive : Ratings.Negative ;
        requestQuery.updateRatings(ratingEnum);
      });
    }

    if (searchParams.get('cashback')) {
      requestQuery.setCashbackOnly(true);
      requestQuery.updateRatings(Ratings.Positive);
      requestQuery.updateRatings(Ratings.Neutral);}

    const sort = searchParams.get('sort');
    switch (sort) {
      case '-combinedScore':
        requestQuery.setSort(CompanySortType.HighScore);
        setActiveSort(CompanySortType.HighScore);
        break;
      case 'combinedScore':
        requestQuery.setSort(CompanySortType.LowScore);
        setActiveSort(CompanySortType.LowScore);
        break;
      default:
        requestQuery.setSort(CompanySortType.CompanyName);
        break;
    }
  }, []);

  const updateSearchParams = () => {
    const { paramsObject, cashbackOnly } = requestQuery;
    const paramsToUpdate = {
      sort: paramsObject.sort,
      ratings: paramsObject.rating,
      cashback: cashbackOnly ? 'true' : undefined,
      values: paramsObject['values.value'],
      sectors: paramsObject['sectors.sector'],
      unsdgs: paramsObject.evaluatedUnsdgs,
    };
    for (const [key, value] of Object.entries(paramsToUpdate)) {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    }
    setSearchParams(searchParams);
  };
  
  useEffect(() => {
    if (sector) requestQuery.updateSectors(sector);
    if (cashback) {
      requestQuery.setCashbackOnly(true);
      // sets to only get positive and negative cashback companies since we don't want to show cashback offers for negative companies
      requestQuery.updateRatings(Ratings.Positive);
      requestQuery.updateRatings(Ratings.Neutral);
    }

  }, []);

  useEffect(() => {
    window.clearTimeout(searchTimeout);
    if (!requestQuery.searchQuery && !hasLoadedCompanies.current) return () => null;
    setSearchTimeout(window.setTimeout(() => {
      loadCompanies(true)();
      if (!!requestQuery.searchQuery) analytics.fireEvent('BC_SearchInput', `${requestQuery.searchQuery}`);
    }, 700));
  }, [requestQuery.searchQuery]);

  useEffect(() => {
    loadCompanies(true)();
    hasLoadedCompanies.current = true;
  }, [activeSort]);

  useEffect(() => {
    if (requestQuery.clear === true) {
      loadCompanies(true)();
      requestQuery.toggleClear();
      updateSearchParams();
    }
  }, [requestQuery.clear]);

  useEffect(() => {
    document.body.classList[filterModalOpen ? 'add' : 'remove']('no-scroll');
  }, [filterModalOpen]);

  const hasFilterModalOpened = useRef(false);

  useEffect(() => {
    if (filterModalOpen && !hasFilterModalOpened.current) hasFilterModalOpened.current = true;
    if (hasFilterModalOpened.current && !filterModalOpen && requestQuery.paramsObject['values.value'] || requestQuery.paramsObject.rating || requestQuery.paramsObject.evaluatedUnsdgs) loadCompanies(true)();
  }, [filterModalOpen]);

  const onSearchClearClick = useCallback(() => {
    requestQuery.setSearchQuery('');
    analytics.fireEvent('BC_ClearSearch');
  }, []);

  const onCompanyClick = useCallback((company: CompanyModel) => () => {
    analytics.fireEvent('BC_CompanyClick', `${company._id}`);
  }, []);

  const onSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    requestQuery.setSearchQuery(e.target.value);
  }, []);

  const onCompanyCompareSelect = useCallback((props: ICompareClickProps) => {
    compareCompaniesModel.updateSelectedCompanies(props.companyId, props.companyName, null, props.companyLogo);
  }, []);

  const onSortDropdownClick = useCallback((option: any) => {
    requestQuery.setSort(option.context);
    setActiveSort(option.context);
    updateSearchParams();
    analytics.fireEvent(`BC_SortSelect_${option.context}`);
  }, []);
  
  const onSectorClick = useCallback((sectorId: any) => {
    requestQuery.updateSectors(sectorId);
    updateSearchParams();
    loadCompanies(true)();
    analytics.fireEvent(`BC_SortSelect_${sectorId}`);
  }, []);

  const onFilterClick = useCallback(() => {
    setFilterModalOpen(filterModalOpen => !filterModalOpen);
    analytics.fireEvent('BC_Filter_Click');
  }, [filterModalOpen]);

  const onCloseFilterModal = useCallback(() => {
    setFilterModalOpen(false);
    analytics.fireEvent('BC_FilterModal_Close');
  }, []);

  const onFilterModalUpdate = useCallback(() => {
    setFilterModalOpen(false);
    updateSearchParams();
    analytics.fireEvent('BC_FilterModal_ShowCompanies');
  }, []);

  const onBackToTopClick = useCallback(() => {
    analytics.fireEvent('BC_BackToTop_Click');
    const searchElement = document.getElementById('browse-companies-search');
    searchElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, []);

  const onSearchEnter = useCallback((e:any) => {
    analytics.fireEvent('BC_Search_Enter');
    if (e.key === 'Enter') {
      const searchElement = document.getElementById('browse-companies-search');
      searchElement.blur();
    }
  }, []);

  const onGetCardClick = useCallback(() => {
    analytics.fireEvent('BC_GetCard_Click');
  }, []);

  const renderClearIcon = useCallback(() => (
    <Button
      kind={ ButtonKind.Blank }
      label='Clear Search'
      onClick={ onSearchClearClick }
    >
      <XIcon stroke={ theme.colors.dark } />
    </Button>
  ), []);

  const renderCompaniesList = useCallback(() => {
    let content: JSX.Element[] = [];
    let placeholderKey = 0;

    if (companiesModel.companies.results.length) {
      content = companiesModel.companies.results.filter(company => !company.isKarmaCollectiveMember).map(company => (
        <CompanyBadge
          company= { company }
          key={ company._id }
          onClick={ onCompanyClick(company) }
          className='browse-company-card'
          onCompareClick={ onCompanyCompareSelect }
          compareCompaniesModel={ compareCompaniesModel }
          compareVisible={ true }
        />
      ));
      
      if (content.length % 4 !== 0) {
        for (let i = 0; i <=2; i++) {
          content.push(<div className='company-placeholder' key={ placeholderKey++ } />);
        }
      }
    } else if (!companiesModel.companies.busy && companiesModel.companies.firstPageLoaded) {
      content.push((
        <NoResults key='no-companies'>
          No companies found
        </NoResults>
      ));
    }

    if (companiesModel.companies.busy) {
      content.push((
        <LoadingSpinnerContainer key='loading-spinner'>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      ));
    }

    if (!companiesModel.companies.allResultsFetched && !companiesModel.companies.busy) {
      content.push(<Waypoint key='waypoint' onEnter={ loadCompanies() } topOffset={ 200 } />);
    }

    return (
      <CompaniesListContainer>
        { content }
      </CompaniesListContainer>
    );
  }, [companiesModel.companies, loadCompanies, compareCompaniesModel.selectedCompanies]);

  const getCardSection = useMemo(() => {
    if (user.isLoggedIn && !user.cardsLoaded || user.hasKarmaWalletCard) return null;

    return (
      <GetKarmaCardSection>
        <p className='take-advantage'>
          <a className='get-card' href='/apply' onClick={ onGetCardClick }>Get the Karma Wallet Card </a>to take advantage of these cashback rewards.
        </p>
        <ButtonLink
          className='unlock-button'
          href='/apply'
          onClick={ onGetCardClick }
        >
          <PillTag
            className='pill-tag'
            size={ PillTagSize.Medium }
            kind={ PillTagKind.ForMembers }
          >
            Unlock Cashback
            <img alt='Karma Wallet App and Card' className='karma-card' src={ KarmaCardImage } />
          </PillTag>
        </ButtonLink>
      </GetKarmaCardSection>
    );
  }, [user.hasKarmaWalletCard, user.cardsLoaded, user.isLoggedIn]);

  const companyCount = useMemo(() => {
    if (companiesModel.companies.busy) return 'Loading companies...';
    const total = companiesModel.companies.total;

    return `${total} ${total === 1 ? 'Company' : 'Companies'}`; 
  }, [companiesModel.companies.busy, companiesModel.companies.total]);

  const renderResults = () => (
    <ResultsContainer>
      { renderCompaniesList() }
    </ResultsContainer>
  );

  const searchContainer = useMemo(() => (
    <SearchContainer className='search-container'>
      <SearchContainerInner>
        <TextField
          className='search-text-field no-shadow text-field'
          fieldKind={ TextFieldKind.Pill }
          id='browse-companies-search'
          label='Search for Company'
          labelHidden
          onChange={ onSearchChange }
          placeholder='Search Companies'
          rightAccessory={ !!requestQuery.searchQuery ? renderClearIcon() : null }
          value={ requestQuery.searchQuery }
          onKeyPress={ onSearchEnter }
        />
      </SearchContainerInner>
    </SearchContainer>
  ), [requestQuery.searchQuery]);

  return (
    <BrowseCompaniesContainer className={ className } title='Browse & Compare Companies'>
      <FilterCompaniesModal
        isOpen={ filterModalOpen }
        onClose={ onCloseFilterModal }
        onFilterModalUpdate={ onFilterModalUpdate }
      />
      <CompareCompaniesModal
        isOpen={ !!(compareCompaniesModel.selectedCompanies.length > 0) }
        companies={ compareCompaniesModel.selectedCompanies }
        compareCompaniesModel={ compareCompaniesModel }
      />
      { searchContainer }
      <BrowseCompaniesInnerWrapper>
        <FilterSortBar
          activeSort={ activeSort }
          sortOptions={ companyOptions }
          onSortClick={ onSortDropdownClick }
          onSectorClick={ onSectorClick }
          selectedSort={ buildSelectedSort() }
          selectedFiltersCount={ selectedFiltersCount }
          onFilterClick={ onFilterClick }
        />
        { getCardSection }
        <CompanyCount>{ companyCount }</CompanyCount>
        <KarmaCollectiveBrowseSection
          compareModel={ compareCompaniesModel }
          onCompareSelect={ onCompanyCompareSelect }
          searchInput={ requestQuery.paramsObject.companyName }
        />
        { renderResults() }
        { 
          showBackToTop &&
          <ArrowButton
            kind={ ArrowButtonKind.Up }
            onClick={ onBackToTopClick }
            className='back-to-top'
          />
        }
      </BrowseCompaniesInnerWrapper>
    </BrowseCompaniesContainer>
  );
};

const BrowseCompaniesObserver = observer(BrowseCompaniesBase);
export const BrowseCompanies = withTheme(BrowseCompaniesObserver);
