import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  stroke?: string;
  id?: string;
}

export const SearchIcon: FC<IProps> = ({ className = '', stroke = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }search-icon` }
    id={ id }
    width={ 22 }
    height={ 22 }
    stroke={ stroke }
    fill='none'
  >
    <path d='M21 21L15.9372 15.9372M15.9372 15.9372C17.5206 14.3538 18.5 12.1663 18.5 9.75003C18.5 4.91754 14.5825 1.00003 9.75 1.00003C4.91751 1.00003 1 4.91754 1 9.75003C1 14.5825 4.91751 18.5 9.75 18.5C12.1662 18.5 14.3537 17.5207 15.9372 15.9372Z' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </SvgIcon>
);
