import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { FlexCenter, FlexRow, PrimaryFont } from '../../styles/styles';

export enum ButtonKind {
  Blank = 'blank',
  Primary = 'primary',
  PrimaryGhost = 'primary-ghost',
  PrimaryWithIcon = 'primary-with-icon',
  Secondary = 'secondary',
  SecondaryGreen = 'secondary-green',
  SecondaryGhost = 'secondary-ghost',
  SecondaryWithIcon = 'secondary-with-icon',
  SecondaryMarketing = 'secondary-marketing',
  SecondaryGhostMarketing = 'secondary-ghost-marketing',
  SecondaryWithIconMarketing = 'secondary-with-icon-marketing',
  Tertiary = 'tertiary',
  TertiaryGhost = 'tertiary-ghost',
  TertiaryWithIcon = 'tertiary-with-icon',
  Quaternary = 'quaternary',
  QuaternaryGhost = 'quaternary-ghost',
  QuaternaryWithIcon = 'quaternary-with-icon',
  Quinary = 'quinary',
  Danger = 'danger',
  DangerGhost = 'danger-ghost',
  DangerWithIcon = 'danger-with-icon',
  GhostMarketing = 'ghost-marketing',
}

export enum ButtonSize {
  Large = 'large',
  Regular = 'regular',
  Small = 'small',
}

interface IProps {
  kind?: ButtonKind;
  size?: ButtonSize;
}

const ButtonStyles = css<IProps>`
  ${ FlexCenter }
  ${ PrimaryFont }
  padding: 10px 25px;
  border-radius: 999px;
  outline: none;
  text-decoration: none;
  transition: .25s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    outline: ${({ theme }) => `1px dashed ${theme.colors.darkGray1}`};
    outline-offset: 1px;
  }

  &:disabled,
  &.disabled {
    pointer-events: none;

    ${({ kind, theme }) => {
    switch (kind) {
      case ButtonKind.Blank:
        return `
          opacity: 0.5;
          transition: .25s ease-in-out;
          border-radius: 999px;
          box-shadow: none;
        `;
      case ButtonKind.PrimaryGhost:
      case ButtonKind.SecondaryGhost:
      case ButtonKind.TertiaryGhost:
      case ButtonKind.QuaternaryGhost:
      case ButtonKind.DangerGhost:
        return `
            background: none;
            border: 1px solid ${theme.colors.lightGray1};
            color: ${theme.colors.disabled};

            svg {
              stroke: ${theme.colors.disabled};
            }

            &:hover {
              background: none;
              border: 1px solid ${theme.colors.lightGray1};
              color: ${theme.colors.disabled};
            }
          `;
      case ButtonKind.PrimaryWithIcon:
      case ButtonKind.SecondaryWithIcon:
      case ButtonKind.TertiaryWithIcon:
      case ButtonKind.QuaternaryWithIcon:
      case ButtonKind.DangerWithIcon:
        return `
            background: none;
            border: none;
            color: ${theme.colors.disabled};
            box-shadow: none;

            &:hover {
              color: ${theme.colors.disabled};

              svg {
                &.withStroke {
                  stroke: ${theme.colors.disabled};
                }
      
                &.withFill {
                  fill: ${theme.colors.disabled};
                }
              }
            }

            svg {
              margin-left: 10px;

              &.withStroke {
                stroke: ${theme.colors.disabled};
              }

              &.withFill {
                fill: ${theme.colors.disabled};
              }
            }
          `;
      default:
        return `
            background: ${theme.colors.lightGray2};
            border: 1px solid ${theme.colors.lightGray2};
            color: ${theme.colors.disabled};

            &:hover {
              background: ${theme.colors.lightGray1};
              border: 1px solid ${theme.colors.lightGray1};
              color: ${theme.colors.disabled};
            }
          `;
    }
  }}
  }

  ${({ theme }) => `
      @media (${theme.breakpoints.mdMax}) {
        &.mobile-full-width {
          min-width: 100%;
          border-radius: 0;
        }
      }
    `}

  svg {
    transition: .25s ease-in-out;
  }

  ${({ size }) => {
    switch (size) {
      case ButtonSize.Large: return `
        font-size: 25px;
        font-weight: 700;
      `;
      case ButtonSize.Small: return `
        font-size: 12px;
        font-weight: 600;
        height: 24px;
        line-height: normal;
        padding: 4px 16px;
      `;
      default: return `
        font-size: 14px;
        font-weight: 500;
      `;
    }
  }}

  ${({ kind, theme }) => {
    switch (kind) {
      case ButtonKind.Blank: return `
          background: none;
          border: none;
          padding: 0;
          outline: none;
          box-shadow: none;
        `;
      case ButtonKind.PrimaryGhost: return `
        background: none;
        border: 1px solid ${theme.colors.primary};
        color: ${theme.colors.primary};

        &:hover {
          border: 1px solid ${theme.colors.primary};
          color: ${theme.colors.primary};
        }
      `;
      case ButtonKind.PrimaryWithIcon: return `
        background: none;
        border: none;
        color: ${theme.colors.primary};
        box-shadow: none;

        &:hover {
          color: ${theme.colors.primary};

          svg {
            &.withStroke {
              stroke: ${theme.colors.primary};
            }

            &.withFill {
              fill: ${theme.colors.primary};
            }
          }
        }

        svg {
          margin-left: 10px;

          &.withStroke {
            stroke: ${theme.colors.primary};
          }

          &.withFill {
            fill: ${theme.colors.primary};
          }
        }
      `;
      case ButtonKind.Secondary: return `
        background: ${theme.colors.darkYellow1};
        border: 1px solid ${theme.colors.darkYellow1};
        color: ${theme.colors.darkGray1};

        &:hover {
          background: ${theme.colors.darkYellow2};
          border: 1px solid ${theme.colors.darkYellow2};
        }
      `;
      case ButtonKind.SecondaryGreen: return `
        background: ${theme.colors.secondary};
        border: 1px solid ${theme.colors.secondary};
        color: ${theme.colors.darkGray1};

        &:hover {
          background: ${theme.colors.secondary};
          border: 1px solid ${theme.colors.secondary};
        }
      `;
      case ButtonKind.SecondaryGhost: return `
        background: none;
        border: 1px solid ${theme.colors.darkYellow1};
        color: ${theme.colors.darkGray1};

        &:hover {
          border: 1px solid ${theme.colors.darkYellow2};
          color: ${theme.colors.darkGray2};
        }
      `;
      case ButtonKind.SecondaryWithIcon: return `
        background: none;
        border: none;
        color: ${theme.colors.darkGray1};
        box-shadow: none;

        &:hover {
          color: ${theme.colors.darkGray2};

          svg {
            &.withStroke {
              stroke: ${theme.colors.darkGray2};
            }

            &.withFill {
              fill: ${theme.colors.darkGray2};
            }
          }
        }

        svg {
          margin-left: 10px;

          &.withStroke {
            stroke: ${theme.colors.darkGray2};
          }

          &.withFill {
            fill: ${theme.colors.darkGray2};
          }
        }
      `;
      case ButtonKind.SecondaryMarketing: return `
      background: ${theme.colors.offWhite};
      border: 1px solid ${theme.colors.offWhite};
      color: ${theme.colors.primary};
      box-shadow: none;

      &:hover {
        background: ${theme.colors.offWhite};
        border: 1px solid ${theme.colors.offWhite};
      }
    `;
      case ButtonKind.SecondaryGhostMarketing: return `
      background: none;
      border: 1px solid ${theme.colors.offWhite};
      color: ${theme.colors.darkGray1};

      &:hover {
        border: 1px solid ${theme.colors.offWhite};
        color: ${theme.colors.darkGray2};
      }
    `;
      case ButtonKind.SecondaryWithIconMarketing: return `
      background: none;
      border: none;
      color: ${theme.colors.darkGray1};
      box-shadow: none;

      &:hover {
        color: ${theme.colors.darkGray2};

        svg {
          &.withStroke {
            stroke: ${theme.colors.darkGray2};
          }

          &.withFill {
            fill: ${theme.colors.darkGray2};
          }
        }
      }

      svg {
        margin-left: 10px;

        &.withStroke {
          stroke: ${theme.colors.darkGray2};
        }

        &.withFill {
          fill: ${theme.colors.darkGray2};
        }
      }
    `;
      case ButtonKind.Tertiary: return `
        background: ${theme.colors.white};
        border: 1px solid ${theme.colors.white};
        color: ${theme.colors.primary};

        &:hover {
          background: ${theme.colors.lightGray1};
          border: 1px solid ${theme.colors.lightGray1};
        }
      `;
      case ButtonKind.TertiaryGhost: return `
        background: none;
        border: 1px solid ${theme.colors.white};
        color: ${theme.colors.white};

        &:hover {
          border: 1px solid ${theme.colors.primary};
          color: ${theme.colors.primary};
        }
      `;
      case ButtonKind.TertiaryWithIcon: return `
        background: none;
        border: none;
        color: ${theme.colors.white};
        box-shadow: none;

        &:hover {
          color: ${theme.colors.lightGray1};

          svg {
            &.withStroke {
              stroke: ${theme.colors.lightGray1};
            }

            &.withFill {
              fill: ${theme.colors.lightGray1};
            }
          }
        }

        svg {
          margin-left: 10px;

          &.withStroke {
            stroke: ${theme.colors.white};
          }

          &.withFill {
            fill: ${theme.colors.white};
          }
        }
      `;
      case ButtonKind.Quaternary: return `
        background: ${theme.colors.darkGray1};
        border: 1px solid ${theme.colors.darkGray1};
        color: ${theme.colors.white};

        &:hover {
          background: ${theme.colors.darkGray2};
          border: 1px solid ${theme.colors.darkGray2};
        }
      `;
      case ButtonKind.QuaternaryGhost: return `
        background: none;
        border: 1px solid ${theme.colors.darkGray1};
        color: ${theme.colors.darkGray1};

        &:hover {
          border: 1px solid ${theme.colors.darkGray2};
          color: ${theme.colors.darkGray2};
        }
      `;
      case ButtonKind.QuaternaryWithIcon: return `
        background: none;
        border: none;
        color: ${theme.colors.darkGray1};
        box-shadow: none;

        &:hover {
          color: ${theme.colors.darkGray2};

          svg {
            &.withStroke {
              stroke: ${theme.colors.darkGray2};
            }

            &.withFill {
              fill: ${theme.colors.darkGray2};
            }
          }
        }

        svg {
          margin-left: 10px;

          &.withStroke {
            stroke: ${theme.colors.darkGray1};
          }

          &.withFill {
            fill: ${theme.colors.darkGray1};
          }
        }
      `;
      case ButtonKind.Quinary: return `
        background: ${theme.colors.light};
        border: 1px solid ${theme.colors.light};
        color: ${theme.colors.darkGray1};

        &:hover {
          background: ${theme.colors.lightGray1};
          border: 1px solid ${theme.colors.lightGray1};
        }
      `;
      case ButtonKind.Danger: return `
        background: ${theme.colors.danger};
        border: 1px solid ${theme.colors.danger};
        color: ${theme.colors.white};

        &:hover {
          background: ${theme.colors.darkDanger};
          border: 1px solid ${theme.colors.darkDanger};
        }
      `;
      case ButtonKind.DangerGhost: return `
        background: none;
        border: 1px solid ${theme.colors.danger};
        color: ${theme.colors.danger};

        &:hover {
          border: 1px solid ${theme.colors.darkDanger};
          color: ${theme.colors.darkDanger};
        }
      `;
      case ButtonKind.DangerWithIcon: return `
        background: none;
        border: none;
        color: ${theme.colors.danger};

        &:hover {
          color: ${theme.colors.darkDanger};

          svg {
            &.withStroke {
              stroke: ${theme.colors.darkDanger};
            }

            &.withFill {
              fill: ${theme.colors.darkDanger};
            }
          }
        }

        svg {
          margin-left: 10px;

          &.withStroke {
            stroke: ${theme.colors.danger};
          }

          &.withFill {
            fill: ${theme.colors.danger};
          }
        }
      `;
      case ButtonKind.GhostMarketing: return `
        ${FlexRow}
        background: none;
        border: 1px solid ${theme.colors.dark};
        color: ${theme.colors.dark};
        gap: 8px;

        &:hover {
          background: none;
          border: 1px solid ${theme.colors.dark};
          color: ${theme.colors.dark};
        }
      `;
      default: return `
        background: ${theme.colors.primary};
        border: 1px solid ${theme.colors.primary};
        color: ${theme.colors.white};

        &:hover {
          background: ${theme.colors.primary};
          border: 1px solid ${theme.colors.primary};
        }
      `;
    }
  }}
`;

export const ButtonContainer = styled.button<IProps>`
  ${ ButtonStyles }
`;

export const ButtonAnchorContainer = styled.a<IProps>`
  ${ ButtonStyles }
  display: inline-block;
`;

export const ButtonLinkContainer = styled(Link)`
  ${ ButtonStyles }
  display: inline-block;
`;
