import React from 'react';
import { ICta } from '../../CTAs';
import { IModalProps } from '../Modal';
import { Body, ConfirmationModalContainer, ConfirmationModalCtas, Title } from './styles';

export interface IConfirmationModalProps extends IModalProps {
  title?: string;
  body?: string | JSX.Element;
  ctas?: ICta[];
  isOpen: boolean;
  onClose(): void;
}

export const ConfirmationModal: React.FC<IConfirmationModalProps> = ({
  title,
  body,
  ctas,
  isOpen,
  onClose,
}) => (
  <ConfirmationModalContainer isOpen={ isOpen } onClose={ onClose }>
    <Title>{ title }</Title>
    <Body>{ body }</Body>
    <ConfirmationModalCtas ctas={ ctas } />
  </ConfirmationModalContainer>
);
