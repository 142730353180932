import React, { useCallback, useEffect, useState } from 'react';
import { CashbackCardsContainer, HeaderContainer, KarmaCollectiveDescriptionContainer, KarmaCollectiveWidgetContainer } from './styles';
import { AccountHubH2 } from '../../styles/components/header';
import { useAnalytics } from '../../contexts/analytics-store';
import { useUserSession } from '../../contexts/user';
import { observer } from 'mobx-react';
import KarmaCardImage from '../../../public/assets/images/empty-kw-card.png';
import { CompanyModel, KarmaCollectiveCompaniesModel } from '../../models/companies';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { CashbackCard } from '../CashbackCard';
import { CardSize } from '../CashbackCard/styles';
import { ButtonLink } from '../Button';
import { ChevronDirection, ChevronIcon } from '../svgs/icons/ChevronIcon';
import { IThemeProps } from '../../styles/themes';
import { withTheme } from 'styled-components';
import { ErrorComponent } from '../ErrorComponent';

export enum KarmaCollectiveOffersWidgetLocation {
  Dashboard = 'AccountDashboard_KarmaCollectiveOffers',
  KarmaCollectiveOffersPage = 'KarmaCollectiveOffers',
}

interface IProps extends IThemeProps {
  className?: string;
  location: KarmaCollectiveOffersWidgetLocation;
  hideCardsCTA?: boolean;
  numberOfCards?: number;
  cardsSize?: CardSize;
}

export const KarmaCollectiveWidgetBase: React.FC<IProps> = ({
  className = '',
  location,
  hideCardsCTA,
  numberOfCards,
  cardsSize,
  theme,
}) => {
  const analytics = useAnalytics();
  const user = useUserSession();
  const karmaCollectiveCompaniesModel = new KarmaCollectiveCompaniesModel;
  const [loading, setLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [karmaCollectiveCompanies, setKarmaCollectiveCompanies] = useState<CompanyModel[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await karmaCollectiveCompaniesModel.loadKarmaCollectiveCompanies();
        setKarmaCollectiveCompanies(karmaCollectiveCompaniesModel.karmaCollectiveCompanies);
        setErrorLoading(false);
      } catch (error) {
        setErrorLoading(true);
        analytics.fireEvent(`${location}_KarmaCollectiveCompanies_Error`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  
  const onSeeAllClick = useCallback(() => {
    analytics.fireEvent('AccountDashboard_KarmaCollective_SeeAll_Click');
  }, []);

  const onGetCardClick = useCallback(() => {
    analytics.fireEvent(`${location}_KarmaCollective_GetCard_Click`);
  }, []);

  const renderOffers = useCallback(() => {
    if (errorLoading) return <ErrorComponent text='Error loading cashback offers' />;
    if (loading) return <LoadingSpinner />;

    let content = [];
    let placeholderKey = 0;

    content = karmaCollectiveCompanies.slice(0, numberOfCards || undefined).map((c) => (
      <CashbackCard
        company={ c }
        location={ location }
        key={ c._id }
        cardSize={ cardsSize }
        hideCTA={ hideCardsCTA }
      />
    ));

    if (content.length % 2 !== 0) {
      content.push(<div className='company-placeholder' key={ placeholderKey++ } />);
    }

    return (
      <CashbackCardsContainer>
        { content }
      </CashbackCardsContainer>
    );
  }, [karmaCollectiveCompanies, user.hasKarmaWalletCard, loading, errorLoading]);

  return (
    <KarmaCollectiveWidgetContainer className={ className }>
      <HeaderContainer>
        <div className='inner-container'>
          <AccountHubH2 className='header'>Karma Collective</AccountHubH2>
          <img alt='Karma Wallet Card' className='karma-card' src={ KarmaCardImage } />
        </div>
        {
          location === KarmaCollectiveOffersWidgetLocation.Dashboard
            ? (
              <ButtonLink
                className='see-all-button'
                href='/account/karma-collective-offers'
                onClick={ onSeeAllClick }
              >
                See All
                <ChevronIcon direction={ ChevronDirection.Right } stroke={ theme.colors.primary } />
              </ButtonLink>
            )
            : null
        }
      </HeaderContainer>
      <KarmaCollectiveDescriptionContainer>
        <p>Karma Collective is a community of sustainable brands bringing you exclusive cashback rewards.</p>
        <p>
          {
            user.hasKarmaWalletCard 
              ?
              <span className='get-card'>Use your Karma Wallet Card </span>
              :
              <a className='get-card' href='/app' onClick={ onGetCardClick }>Get the Karma Wallet Card </a>
          }
          to take advantage of these cashback rewards.
        </p>
      </KarmaCollectiveDescriptionContainer>
      { renderOffers() }
    </KarmaCollectiveWidgetContainer>
  );
};

const KarmaCollectiveWidgetObserver = observer(KarmaCollectiveWidgetBase);
export const KarmaCollectiveWidget = withTheme(KarmaCollectiveWidgetObserver);
