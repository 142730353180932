import { BadgeCarousel, ContentContainer, KarmaCollectiveBrowseSectionContainer, LoadingSpinnerContainer } from './styles';
import { AccountHubH3, H6 } from '../../styles/components/header';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CompanyBadge, ICompareClickProps } from '../CompanyBadge';
import { useAnalytics } from '../../contexts/analytics-store';
import { useSearchParams } from 'react-router-dom';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { CompaniesModel, CompanyModel } from '../../models/companies';
import { observer } from 'mobx-react';
import { CompareCompaniesModel } from '../../models/compare-companies';
import { IParamsObject } from '../../models/browse-query';
import { useAppData } from '../../contexts/app';
import { Settings as ReactSlickSettings } from 'react-slick';
import { CarouselArrowsKind } from '../Carousel';

interface IProps {
  className?: string;
  compareModel: CompareCompaniesModel;
  onCompareSelect: (props: ICompareClickProps) => void;
  searchInput?: string;
}

const carouselSettings: ReactSlickSettings = {
  dots: false,
  infinite: false,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 3,
};

export const KarmaCollectiveBrowseSectionBase: React.FC<IProps> = ({
  className = '',
  compareModel,
  onCompareSelect,
  searchInput,
}) => {
  const analytics = useAnalytics();
  const appData = useAppData();
  const companiesModel = useRef(new CompaniesModel({ limit: 50 })).current;
  const [loading, setLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [searchTimeout, setSearchTimeout] = useState<number>(null);

  const generateQueryFromSearchParams = useCallback(() => {
    const query: IParamsObject = {
      evaluatedUnsdgs: '',
      karmaCollectiveMember: 'true',
      'merchant!': 'null',
      rating: '',
      'sectors.sector': '',
      sort: '',
      'values.value': '',
    };

    if (searchParams.has('sort')) {
      query.sort = searchParams.get('sort');
    }
    if (searchParams.has('sectors')) {
      query['sectors.sector'] = searchParams.get('sectors');
    }
    if (searchParams.has('ratings')) {
      query.rating = searchParams.get('ratings');
    }
    if (searchParams.has('unsdgs')) {
      query.evaluatedUnsdgs = searchParams.get('unsdgs');
    }
    if (searchParams.has('values')) {
      query['values.value'] = searchParams.get('values');
    }

    return query;
  }, [searchParams]);

  const onCompanyClick = useCallback((company: CompanyModel) => () => {
    analytics.fireEvent('BC_karmaCompanyClick', `${company._id}`);
  }, []);

  const loadCompanies = useCallback(async () => {
    setLoading(true);
    const karmaCollectiveQuery : IParamsObject = { ...generateQueryFromSearchParams(), companyName: searchInput };

    try {
      await companiesModel.companies.refresh(karmaCollectiveQuery);
    } catch (err: any) {
      analytics.fireEvent('BC_ErrorLoadingKarmaCompanies');
      setErrorLoading(true);
    } finally {
      setLoading(false);
    }
  }, [generateQueryFromSearchParams, searchInput]);

  useEffect(() => {
    window.clearTimeout(searchTimeout);
    if (searchInput === '') return () => null;
    setSearchTimeout(window.setTimeout(() => {
      loadCompanies();
    }, 700));
  }, [searchInput]);

  useEffect(() => {
    loadCompanies();
  }, []);

  const companiesList = useMemo(() => {
    let content: JSX.Element[] = [];
    const companyBadgeClassName = `${companiesModel.companies.results.length === 1 ? 'single-company' : ''} company-badge`;

    if (loading) {
      content.push((
        <LoadingSpinnerContainer key='loading-spinner'>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      ));
    }

    if (errorLoading) {
      content.push((
        <H6 className='results-text' key='error-loading-companies'>
          Error loading Karma Collective companies
        </H6>
      ));
    }

    if (companiesModel.companies.results.length > 0) {
      content = companiesModel.companies.results.map(c => (
        <CompanyBadge
          key={ c._id }
          company={ c }
          onClick={ onCompanyClick(c) }
          className={ companyBadgeClassName }
          compareCompaniesModel={ compareModel }
          compareVisible={ true }
          onCompareClick={ onCompareSelect }
        />
      ));
    }

    if (companiesModel.companies.results.length === 0 && !companiesModel.companies.busy) {
      content.push((
        <H6 className='results-text' key='no-companies-found'>
          No Karma Collective companies found
        </H6>
      ));
    }

    const shouldRenderCarousel = appData.windowWidth > 1450 && companiesModel.companies.results.length > 3;
    const ContainerComponent: React.ElementType = shouldRenderCarousel ? BadgeCarousel : ContentContainer;
    const containerProps = shouldRenderCarousel ? { settings: carouselSettings, arrowsKind: CarouselArrowsKind.Blank } : {};

    return (
      <ContainerComponent { ...containerProps }>
        { content }
      </ContainerComponent>
    );
  }, [loading, errorLoading, companiesModel.companies.results, onCompanyClick, compareModel.selectedCompanies, appData.windowWidth]);

  if (companiesModel.companies.results.length === 0 && companiesModel.companies.allResultsFetched || companiesModel.companies.busy) return null;

  return (
    <KarmaCollectiveBrowseSectionContainer className={ className }>
      <AccountHubH3>Karma Collective</AccountHubH3>
      <p className='karma-collective-description'>The Karma Collective is a community of sustainable brands bringing you exclusive cashback rewards.</p>
      { companiesList }
    </KarmaCollectiveBrowseSectionContainer>
  );
};

export const KarmaCollectiveBrowseSection = observer(KarmaCollectiveBrowseSectionBase);
