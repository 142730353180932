import styled from 'styled-components';
import { _H2 } from '../../../styles/components/header';
import { FlexCenter, FlexColCenter, FlexHorizontalCenter } from '../../../styles/styles';
import { CTAs } from '../../CTAs';
import { Modal } from '../Modal';

export const Body = styled.div`
  min-height: 20px;
  margin: 30px 0;
  text-align: center;
`;

export const Graphic = styled.div`
  ${FlexCenter}
`;

export const ConfirmationModalContainer = styled(Modal)`
  width: 90%;
  max-width: 650px;
  padding: 35px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 60px;
  }
`;

export const ConfirmationModalCtas = styled(CTAs)`
  ${FlexColCenter}
  padding: 0;

  button {
    &[kind="blank"] {
      padding: 10px 0 0;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:last-child {
      margin-left: 0;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexHorizontalCenter}

    button {
      &[kind="blank"] {
        padding: 0;
      }

      &:not(:last-child) {
        margin-bottom: 0;
      }
  
      &:last-child {
        margin-left: 10px;
      }
    }
  }

  @media (${({ theme }) => theme.breakpoints.smMax}) {
    button, a {
      width: 100%;
      text-align: center;
    }
  }
`;

export const Title = styled.div`
  ${_H2}
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
`;
