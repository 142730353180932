import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { AppType, FRONTEND_URL } from '../../constants';
import { useAnalytics } from '../../contexts/analytics-store';
import { removeSpecialCharacters } from '../../lib/misc';
import { SocialLinks } from '../SocialLinks';
import {
  AltLinksContainer,
  FooterAnchor,
  FooterContainer,
  FooterInnerContainer,
  FooterLink,
  FooterLinkContainer,
  LegalContainer,
  LinksContainer,
  LogoContainer,
  CerftificationsContainer,
  FooterLinkGroupContainer,
} from './styles';
import { KarmaWhiteKLogo } from '../svgs/logos/KarmaWhiteKLogo';
import { OnePercentPlanetWhite } from '../svgs/certifications/OnePercentPlanetWhite';
import bcorpwhite from '../../../public/assets/images/b-corp-white.png';
import { ContactUs } from '../ContactUs';
import { ButtonLink } from '../Button';
import { GroupFooter } from '../GroupFooter';
import { DoneGoodLogo } from '../svgs/logos/DoneGoodLogo';

interface IProps {
  className?: string;
  appType?: AppType;
}

interface IFooterLink {
  text: string;
  path?: string;
  /**
   * used for external links only
   */
  url?: string;
  target?: string;
  rel?: string;
}

type IFooterLinkGroup = IFooterLink[];

const links: IFooterLinkGroup[] = [
  [
    {
      text: 'Home',
      path: '/',
    },
    {
      text: 'Company Ratings',
      path: '/browse-and-compare-companies',
    },
    {
      text: 'News & Insights',
      url: 'https://karmawallet.io/blog/',
    },
  ],
  [
    {
      text: 'Rating System',
      path: '/rating-system',
    },
    {
      text: 'Our Impact',
      path: '/our-impact',
    },
    {
      text: 'Karma Wallet Card',
      path: '/app',
    },
  ],
  [
    {
      text: 'About Us',
      path: '/about',
    },
    {
      text: 'Press',
      url: 'https://blog.karmawallet.io/press/',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      text: 'DoneGood',
      url: 'https://www.donegood.co',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  ],
  [
    {
      text: 'Employers',
      path: '/employers',
    },
    {
      text: 'Karma Collective',
      path: '/karma-collective',
    },
    // {
    //   text: 'Banks & Credit Unions',
    //   path: '/banks-credit-unions',
    // },
  ],
  [
    {
      text: 'Contact Us',
      path: '/contact-us',
    },
    {
      text: 'FAQs',
      path: '/faqs',
      target: '_blank',
    },
    // {
    //   text: 'Partnerships',
    //   path: '/partnerships',
    // },
  ],
];

const developerSiteLinks: IFooterLinkGroup[] = [
  [
    {
      text: 'Home',
      path: '/',
    },
    {
      text: 'API Docs',
      path: '/api-docs',
    },
    {
      text: 'SDK',
      path: '/sdk',
    },
    {
      text: 'Karma Wallet',
      url: FRONTEND_URL,
    },
  ],
];

const copyright = `©${dayjs().format('YYYY')} ImpactKarma Inc. All rights reserved.`;

const getFooterLinks = (appType: AppType) => {
  switch (appType) {
    case 'karma':
      return links;
    case 'admin':
      return links;
    case 'developer':
      return developerSiteLinks;
    default:
      return links;
  }
};

export const Footer: React.FC<IProps> = ({ className = '', appType }) => {
  const analytics = useAnalytics();

  const onLinkClick = useCallback(
    (apptype: AppType, linkName: string) => () => {
      if (!!apptype && appType === 'developer') {
        analytics.fireEvent(`Developer_Footer_${removeSpecialCharacters(linkName)}`);
      } else {
        analytics.fireEvent(`Footer_${removeSpecialCharacters(linkName)}`);
      }
    },
    [],
  );

  const renderLegalContainer = useCallback(() => (
    <LegalContainer>
      <ButtonLink
        onClick={ onLinkClick(appType, 'Privacy') }
        target='_blank'
        href='/privacy-policy'
        rel='noreferrer'
      >
        Privacy Policy
      </ButtonLink>
      <ButtonLink
        onClick={ onLinkClick(appType, 'PathwardPrivacy') }
        target='_blank'
        href='https://cdn.karmawallet.io/documents/pathward_privacy_policy.pdf'
        rel='noreferrer'
      >
        Pathward Privacy Policy
      </ButtonLink>
      <ButtonLink
        onClick={ onLinkClick(appType, 'CardHolderAgreement') }
        target='_blank'
        href='https://cdn.karmawallet.io/documents/karma_wallet_pathward_cardholder_agreement.pdf'
        rel='noreferrer'
      >
        Cardholder Agreement
      </ButtonLink>
      <ButtonLink
        onClick={ onLinkClick(appType, 'TermsConditions') }
        target='_blank'
        href='https://cdn.karmawallet.io/documents/impactkarma_terms_and_conditions.pdf'
        rel='noreferrer'
      >
        Terms & Conditions
      </ButtonLink>
    </LegalContainer>
  ), [appType]);

  const renderAltLinks = useCallback((cn: string) => {
    let content;

    if (cn === 'dt-alt-links-container ') {
      content = (
        <>
          <p className='copyright'>{ copyright }</p>
          <SocialLinks />
          { renderLegalContainer() }
        </>
      );
    } else {
      content = (
        <>
          <SocialLinks />
          <p className='copyright'>{ copyright }</p>
          { renderLegalContainer() }
        </>
      );
    }

    return (
      <AltLinksContainer className={ cn }>
        { content }
      </AltLinksContainer>
    );
  }, [appType]);

  const renderLinks = () => {
    const _links = getFooterLinks(appType).map( linkGroup => {
      const groupedLinks = linkGroup.map(({ text, path, url, target, rel }) => {
        if (appType === 'developer' && text === 'FAQs') return null;
        const key = `footer-link-${text.toLowerCase().replace(' ', '-')}`;
        const _link = !!url ? (
          <FooterAnchor
            onClick={ onLinkClick(appType, text) }
            href={ url }
            target={ target }
          >
            { text }
          </FooterAnchor>
        ) : (
          <FooterLink
            onClick={ !!path ? onLinkClick(appType, text) : (e) => e.preventDefault() }
            disabled={ !path }
            to={ path }
            target={ target }
            rel={ rel }
          >
            { text }
          </FooterLink>
        );
  
        return <FooterLinkContainer key={ key }>{ _link }</FooterLinkContainer>;
      });

      return appType === 'developer' ? groupedLinks : <FooterLinkGroupContainer key={ linkGroup[0].text + '-link-group' }>{ groupedLinks }</FooterLinkGroupContainer>;
    });

    return <LinksContainer aria-label='Footer Navigation'>{ _links }</LinksContainer>;
  };

  if (location.pathname.includes('/groups')) return <GroupFooter />;

  return (
    <FooterContainer className={ className }>
      <FooterInnerContainer>
        <LogoContainer>
          <Link
            onClick={ onLinkClick(appType, 'LogoClick') }
            to='/'
            aria-label='Link to Karma Wallet homepage.'
          >
            <KarmaWhiteKLogo />
          </Link>
        </LogoContainer>
        { renderLinks() }
        <CerftificationsContainer>
          <OnePercentPlanetWhite />
          <img src={ bcorpwhite } alt='B Corp' />
          <DoneGoodLogo />
        </CerftificationsContainer>
        { renderAltLinks('mb-alt-links-container ') }
        { renderAltLinks('dt-alt-links-container ') }
        <ContactUs />
      </FooterInnerContainer>
    </FooterContainer>
  );
};
