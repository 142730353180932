import { DefaultTheme } from 'styled-components';

/**
 * ONLY USE HEX VALUES AND ALWAYS USE FORMAT: #XXXXXX
 *
 * there are transparencies calculated throughout app that will break if
 * any other color value type is used, or if format: #XXX is used.
 */

export interface IThemeProps {
  theme: DefaultTheme;
}

export const theme: DefaultTheme = {
  breakpoints: {
    xxsMin: 'min-width: 380px',
    xsMin: 'min-width: 476px',
    xsMax: 'max-width: 576px',
    smMin: 'min-width: 577px',
    smMax: 'max-width: 767px',
    mdMin: 'min-width: 768px',
    mdMax: 'max-width: 991px',
    lgMin: 'min-width: 992px',
    lgMax: 'max-width: 1199px',
    xlMin: 'min-width: 1200px',
    xlMax: 'max-width: 1399px',
    xxlMin: 'min-width: 1400px',
  },
  colors: {
    tertiary: '#E5D976',
    light: '#F2F5F5',
    lightGray: '#DCE2E2',
    midGray: '#99A4A6',
    darkGray: '#4C575E',
    dark: '#001C20',
    background: '#E5E5E5',
    darkGray1: '#283237',
    darkGray2: '#313D43',
    darkGray3: '#49575F',
    darkGray4: '#7F8C8E',
    lightGray1: '#C6C6C6',
    lightGray2: '#E4E4E4',
    green: '#95D28B',
    lightGreen1: '#B8E0B6',
    lightGreen2: '#E2F5E1',
    lightGreen3: '#55ED6D1A',
    darkGreen1: '#6E9370',
    darkGreen2: '#1E5E22',
    darkGreen3: '#1F3D33',
    darkGreen4: '#0E2425',
    lightYellow1: '#F6EFB6',
    lightYellow2: '#FFFDEE',
    darkYellow1: '#E7D651',
    darkYellow2: '#CFC04C',
    lightBlue1: '#64949B',
    lightBlue2: '#A2D7DF',
    disabled: '#BABAC2',
    success: '#4CAF50',
    lightDanger: '#F76070',
    danger: '#FF0D25',
    darkDanger: '#BA0719',
    positiveLight: '#A3F3D3',
    neutralLight: '#FFF2A3',
    negativeLight: '#CD93C8',
    negative2: '#EC5F58',
    // Start Of Updating Colors ---------------------
    black: '#001C20',
    white: '#FFFFFF',
    primary: '#287E8A',
    secondary: '#5CC87F',
    grey1: '#F2F5F5',
    grey2: '#DCE2E2',
    grey3: '#99A4A6',
    grey4: '#7F8C8E',
    grey5: '#4C575E',
    grey6: '#C4C4C4',
    green1: '#E5F3E8',
    green2: '#C0E0CE',
    green3: '#55ED6D',
    teal1: '#B0CACF',
    gold: '#E5D976',
    gold1: '#FCFDEC',
    gold2: '#E3E6CD',
    blue: '#5CAEC8',
    blue1: '#E2ECEE',
    blue2: '#BED8E2',
    red: '#EC5F58',
    red1: '#F2D0CE',
    offWhite: '#FCFDEC',
    positive: '#5CC87F',
    negative: '#EC5F58',
    neutral: '#E5D976',
  },
};
