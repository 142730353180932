import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import client, { Socket } from 'socket.io-client';
import { SiteCookies } from '../lib/cookies';

declare const __KARMA_ENV__: string;
declare const __REACT_APP_API_PORT__: string;
declare const __REACT_APP_API_URL__: string;

export interface ISocketEvent {
  type: string;
  data: any;
}

interface IAuthObj {
  token: string;
}

export class SocketClient {
  private _forceDisconnectWithoutReconnect = false;
  private _client: Socket = null;
  private _baseUrl = __KARMA_ENV__ === 'local'
    ? `${__REACT_APP_API_URL__}:${__REACT_APP_API_PORT__}`
    : `${__REACT_APP_API_URL__}`;

  get isConnected() {
    return this._client?.connected;
  }

  public connect = () => {
    this._client = client(
      this._baseUrl, 
      { 
        reconnection: true,
        reconnectionDelayMax: 2000,
        transports: ['websocket'],
        auth: { token: Cookies.get(SiteCookies.AuthKey) || Cookies.get(SiteCookies.LegacyAuthKey) },
      },
    );

    this._client.on('connect', () => {
      // eslint-disable-next-line no-console
      console.log(`%csocket connected - ${ dayjs().format('MMM DD, YYYY @ hh:mm A')}`, 'color: yellow');
    });

    this._client.on('disconnect', () => {
      if (this._forceDisconnectWithoutReconnect) return;
      (this._client.auth as IAuthObj).token = Cookies.get(SiteCookies.AuthKey) || Cookies.get(SiteCookies.LegacyAuthKey);
      this._client.connect();
    });
  };

  public disconnect = () => {
    this._forceDisconnectWithoutReconnect = true;
    this._client.disconnect();
  };

  public emit = (ev: string, data?: any) => {
    this._client.emit(ev, data);
  };

  public off = (ev: string, listener: (data: ISocketEvent) => void) => {
    this._client?.off(ev, listener);
  };

  public on = (ev: string, listener: (data: ISocketEvent) => void) => {
    if (!this._client?.connected) {
      setTimeout(() => this.on(ev, listener), 50);
    } else {
      this._client?.off(ev, listener); // prevent duplicate listeners
      this._client.on(ev, listener);
    }
  };

  public reconnect = () => {
    if (this._client) {
      this._client.disconnect();
    } else {
      this.connect();
    }
  };
}
